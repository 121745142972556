import React from 'react';
import { mergeProps } from 'next-merge-props';

import { getServerSidePageProps } from '../modules/page/getServerSidePageProps';
import { getServerSideCookieProps } from '../modules/cookies/getServerSideCookieProps';

import { PageProps } from '../context/PageData';

import Page from '../components/Page';

export default function DynamicPage(data: PageProps) {
  return <Page {...data} />;
}

export const getServerSideProps = mergeProps([
  getServerSidePageProps,
  getServerSideCookieProps
]);
